.MuiToolbar-root span.e-input-group {
    width: 180px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #D4D9E5;
    color: #2A2940;
    padding: 0 5px 5px;
    margin: 0;
}
input#ej2-datetimepicker_0 {
    text-align: center;
}
